import request from '@/utils/request'

function login(data){	//登录
 return request({
    url: '/api/front/login',
    method: 'post',
    data: data ,
    withCredentials: false 
 })
}
// function logout(){	//退出
//  return request({
//     url: '/api/admin/logout',
//     method: 'post',
//  })
// }
function faceLogin(formData){	//人脸登录
 return request({
    url: '/api/front/check_picture_to_check_face',
    method: 'post',
    data: formData ,
    headers: {			//设置上传请求头
        'Content-Type': 'multipart/form-data'
    },
 })
}



export default {
	login,
   faceLogin
}